/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Space+Mono:wght@700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=PT+Serif&family=Space+Mono:wght@700&display=swap");

:root {
  --color-background: #f7f0de;
  --color-primary: #ea9713;
  --color-secondary-rgba: 37, 33, 32;
  --color-secondary: rgba(var(--color-secondary-rgba));
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  font-size: 10px;
}

p {
  font-size: 2.5rem;
  color: var(--color-secondary);
}

.nav_home {
  position: absolute;
  top: 2rem;
  left: 4rem;
  color: rgba(var(--color-secondary-rgba), 0.85);
  font-family: "montserrat";
  font-size: 40px;
  letter-spacing: -3px;
}

.nav_home:hover {
  color: var(--color-secondary);
}

@media (max-width: 675px) {
  html {
    font-size: 7px;
  }

  p {
    font-size: 4rem;
  }
  .nav_home {
    left: 2.5rem;
  }
}

@media (max-width: 500px) {
  html {
    font-size: 5px;
  }

  p {
    font-size: 5rem;
  }
}

h1 {
  font-family: "Space Mono", monospace;
  font-size: 16rem;
  color: var(--color-primary);
  line-height: 90%;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: "montserrat";
  color: rgba(var(--color-secondary-rgba), 0.85);
}

a:hover {
  color: var(--color-secondary);
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.index__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.index__container::before {
  content: "";
  flex: 1;
}

.index__container::after {
  content: "";
  flex: 3;
}

.index__nav {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.index__nav_item {
  flex: 1;
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
}

@media (max-width: 500px) {
  .index__nav {
    flex-direction: column;
  }

  .index__nav_item {
    font-size: 6rem;
  }
}

#portfolio {
  overflow-y: scroll;
  position: relative;
}

.portfolio__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio__video_grid {
  flex: 1;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 10rem;
}

.portfolio__video_grid_item {
  margin: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  background-color: rgba(var(--color-secondary-rgba), 0.1);
}

.portfolio__video_grid_item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 900px) {
  #portfolio h1 {
    font-size: 12rem;
  }

  .portfolio__video_grid {
    grid-template-columns: 1fr;
  }
}

#about {
  overflow-y: scroll;
  position: relative;
}

.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 4rem 0rem 4rem;
}
.about__container p:last-child {
  padding-bottom: 10rem;
}

.about__container p {
  max-width: 100rem;
  font-family: "PT Serif", serif;
}
